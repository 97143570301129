import React,{Component} from 'react';
import $ from 'jquery/dist/jquery.js';
import { Controlled as CodeMirror5 } from 'react-codemirror2';
import { MDBInput } from 'mdbreact';
import atclogo from '../../images/loader.png';
import {Badge} from 'react-bootstrap';
import whoAmILogo from '../../images/quiz-icon.png';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/lucario.css';

import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/css/css';
import 'codemirror/mode/javascript/javascript';





class editorQuizAssessments1 extends Component{ 
   constructor(props){
   	super(props)

   	this.state = {
       taskRef:this.props.taskRef, 
       taskByteValue:"",
       check:'',
       codeSnippet:this.props.codeSnippet

   	}



 this.liftQuizResponse = this.liftQuizResponse.bind(this);
 this.resetSubmissionStatusToFalse = this.resetSubmissionStatusToFalse.bind(this);
   this.textReader = this.textReader.bind(this);

   }

  async componentDidUpdate(prevProps){  
  
     console.log('updated')
  }


  async componentDidMount(){
 
   //this.props.byteState(this.state.taskByteValue);

   this.resetSubmissionStatusToFalse()
  }



async resetSubmissionStatusToFalse(){ 

   let resetQuizStatus = await fetch('https://atcbrain.herokuapp.com/learning-engine/response-api/v1/assessments/unsubmit', { 
    method: "POST",
    headers:{
      'content-type':'application/JSON'
    },
    mode:'cors',
    credentials:'include'
   })
  
   .then(response => response.json())
   .then(response => console.log (response))

}


async liftQuizResponse(event){
 
 let quizFormInput = document.getElementById('quiz')

 let response = $(quizFormInput).val()

 console.log(event.target.value)

 this.props.quizResponseUpdate(event.target.value, this.props.tag, this.props.assessmentRef)

}

async textReader(){
  console.log('spoke')
  $.when(window.responsiveVoice.speak(`Analyse this`))
  .then(window.responsiveVoice.speak(`${this.props.question}`))
  .then(window.responsiveVoice.speak(`is it ${this.props.optionA}`))
  .then(window.responsiveVoice.speak(`or ${this.props.optionB}`))
  .then(window.responsiveVoice.speak(`or ${this.props.optionC}`))
}





runCode = () => {
    const {html} = this.state;

    const iframe = this.refs.iframe;
    const document = iframe.contentDocument;
    const documentContents = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta http-equiv="X-UA-Compatible" content="ie=edge">
        <title>Document</title>
        <style>
       
        </style>
      </head>
      <body>
        ${html}
      </body>
      </html>
    `;

    document.open();
    document.write(documentContents);
    document.close();
  };



render() {


    const { html } = this.state;
    const codeMirrorOptions = {
      mode: 'htmlmixed', 
      tabMode: 'indent',
      theme: 'lucario',
      value: this.props.codeSnippet,
      lineNumbers: true,
      scrollbarStyle: null,
      lineWrapping: true,
      smartIndent: true,
      electricChars: true,
      autofocus: true,
      lineSeparator:"    ",
      readOnly: true
      
    };



const divStyle = {
      backgroundImage: 'url("../../images/hero-large.png")',
      WebkitTransition: 'all', // note the capital 'W' here
      msTransition: 'all' // 'ms' is the only lowercase vendor prefix
};

const optionsArray= [this.props.optionA, this.props.optionB, this.props.optionC]

const optionsList = optionsArray.map(

	options => <div className="col-md-4 text-center white-text header-font"> <MDBInput onChange={this.liftQuizResponse} id="quiz" label={options} filled type="radio" name="opt" value={options} />  </div>

	)

	return(
  <div className="bottom-right-curve top-right-curve" id="editor-properties" > 
   <div className="row standard-padding-tb-3 black-background top-right-curve full-width ml-off" id="editor-instructions"> 
             <div className="col-12 col-md-2 text-center"> 
             <figure> 
               <button onClick={this.textReader} className="azure-bg" data-toggle="tooltip" data-placement="right" title="Click for voice">
              <img src={whoAmILogo} width="60"/> 
              </button>
              <figcaption className="">
               <p className="body-font yellow-background tiny-font"> Code Analysis </p> 
               <p className="accent-font tiny-font white-text"> Complete the tasks by following the instructions </p>
              </figcaption> 
             </figure>
             </div>      

             <div className="col-md-10 standard-margin-top-quiz">
              <div className="row">

                <div className="col-12 text-right">
                <Badge className="badge badge-danger" variant="danger">Graded</Badge>

             </div>
               <div className="col-12"> 
                     <h3 className="header-font white-text"> Riddle </h3> 
             <p className="header-font white-text"> {this.props.question} </p>
         

               </div>
               <div className="col-12">
               <div className="row">
     {optionsList}
     </div>
   </div>
              </div>
        
            </div>

   </div> 

   <div className="row bottom-right-curve full-width black-background ml-off"> 


 <div className="col-12 padding-off bottom-right-curve CodeMirror2">  <div className="App row">
         
         <section className="playground col-12 col-md-6">
           <div className="code-editor html-code">
             <div className="editor-header">MIXED HTML</div>
             <CodeMirror5
               value={html}
               options={{
                 mode: 'htmlmixed',
                 ...codeMirrorOptions,
               }}
               onBeforeChange={(editor, data, html) => {

                 this.setState({ html });
               }}

               onChange={this.runCode}
               
             />
           </div>
         </section>
         <section className="result col-12 col-md-6 white-background padding-off">
           <iframe title="result" className="iframe" ref="iframe" />
         </section>
       </div>
  </div>





   </div>
 </div>

		)
}



}

export default editorQuizAssessments1